(function($) {

	$.fn.initSubmenuClick = function() {

		this.click(function() {

			var id = (this).getAttribute("data-id");

		/*
			if ($(this).is(".on")) {

				$(".item-menu").removeClass("on");

				$(this).removeClass("on");
				$('.uroven-1').removeClass("on");
				$('.submenu').removeClass('open-submenu');
				$(".item-menu").removeClass("hide");
				$("#menu-back").removeClass("open");

			}else {

				$(this).addClass("on");
				$(this).parent().addClass("on");
				$('#submenu-'+id).addClass('open-submenu');
				$(".item-menu").addClass("hide");
				$("#menu-back").addClass("open");

			}
		*/

			$(this).parent().addClass("close");
			$(".menu-level-2").addClass("open");
			$('#submenu-'+id).addClass('open-submenu');

		});

		return this;

	}

})(jQuery);



(function($) {

	$.fn.initSubmenuOneClick = function() {

		this.click(function() {

			var id = (this).getAttribute("data-submenu");

			$(this).parent().parent().addClass("close");
			$(this).parent().parent().removeClass("open");
			$(".menu-level-3").addClass("open");
			$('#submenu-level-3-'+id).addClass('open-submenu');

		});

		return this;

	}

})(jQuery);


(function($) {

	$.fn.initBackOne = function() {

		this.click(function() {

			$(".menu-level-1").removeClass("close");
			$(this).parent().removeClass("open");
			$(".submenu").removeClass("open-submenu");
			$(".submenu-level-3").removeClass("open-submenu");

		});

		return this;

	}

})(jQuery);


(function($) {

	$.fn.initBackTwo = function() {

		this.click(function() {

			$(".menu-level-2").removeClass("close");
			$(".menu-level-2").addClass("open");
			$(this).parent().removeClass("open");
			$(".submenu-level-3").removeClass("open-submenu");

		});

		return this;

	}

})(jQuery);


$(function() {

	$(".level-1-click").initSubmenuClick();

});

$(function() {

	$(".level-2-click").initSubmenuOneClick();

});

$(function() {

	$("#menu-back-level-2").initBackOne();

});


$(function() {

	$("#menu-back-level-3").initBackTwo();

});






