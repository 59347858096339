(function($) {

	$.fn.initHeroScrollClick = function() {

		this.click(function() {

			$(window).scrollTo("#realization", 500,  {offset: 0});

		});

		return this;


	}

})(jQuery);


$(function() {

	$(".hero .item").initHeroScrollClick();

});
