$(function() {

	$("#people-in").slidehide({
		"x":1, "y":0, "alpha":true, "auto":0,
		"slides":".items",
		"contHeight":true,
		"arrowNext":"#arrow-left-people",
		"arrowPrev":"#arrow-right-people",
		"grouping": function(){
			var sirka = $(window).width();

			if (sirka < 480) {

				return 1;

			}else if (sirka < 780) {

				return 2;

			}else if (sirka < 1050) {

				return 3;

			}else if (sirka < 1200) {

				return 4;

			}else {

				return 5;

			}
		},
		"touch":true
	});

});
