$(function() {
	if ($(window).width() < 480) {

		$("#images").slidehide({
			"x":1, "y":0, "alpha":true, "auto":0,
			"slides":".image",
			"arrowNext":"#arrowNext",
			"arrowPrev":"#arrowPrev",
			"contHeight":true,
			// "grouping":1,
			"buttons":"#links-button",
			"touch":true
		});

	}else {

		$("#images").slidehide({
			"x": 1, "y": 0, "alpha": true, "auto": 0,
			"slides": ".image",
			"arrowNext": "#arrowNext",
			"arrowPrev": "#arrowPrev",
			"contHeight": true,
			// "grouping":1,
			"buttons": "#links-button",
			"touch": false
		});

	}




});
