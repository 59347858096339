$(function() {


	if($(window).width() <= 1100 & $(window).width() >= 900) {

		$('.navigation-footer').columnize({columns: 3});

	}else if($(window).width() <= 900){

		$('.navigation-footer').columnize({columns: 2});

	}else {

		$('.navigation-footer').columnize({columns: 4});

	}

});
