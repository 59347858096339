$(function() {

	$(".title-content .news").slidehide({
		"x":0, "y":0, "alpha":true, "auto":10000,
		"slides":".item",
		"contHeight":true,
		"grouping": function(){
			var sirka = $(window).width();
			if (sirka < 480) {
				return 1;

			}else {
				return 3;
			}
		},
		"buttons":"#links-button-news",
		"touch":true
	});

});
