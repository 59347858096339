(function($) {

	$.fn.initTopScrollClick = function() {

		this.click(function() {

			$(window).scrollTo("body", 500,  {offset: 0});

		});

		return this;


	}

})(jQuery);


$(function() {

	$("#top-link").initTopScrollClick();

});
