(function($) {

	$.fn.initMosaicGallery = function() {

		var _this = this;

		_this.addClass('preparing');

		_this.waypoint(function() {
			_this.removeClass('preparing');				
		}, {
			'offset': function() {
				return Waypoint.viewportHeight() - 300;
			}
		});

		window.setTimeout(function() {
			_this.addClass('animated')
		}, 100);

		return this;

	};

})(jQuery);