(function($) {

	$.fn.initContactsMap = function() {

		var _this = this;

		function changeRegion(regionName) {
			_this.find('.contacts-region').addClass('inactive');
			_this.find('.contacts-region[data-region="' + regionName + '"]').removeClass('inactive');

			_this.find('.region-link').removeClass('active');			
			_this.find('.region-link[data-region="' + regionName + '"]').addClass('active');			

			_this.find('.map-region').attr('class', 'map-region');
			_this.find('.map-region[data-region="' + regionName + '"]').attr('class', 'map-region active');
		}

		changeRegion(
			_this.find('.contacts-region').eq(0).attr('data-region')
		);

		_this.find('.region-link').click(function() {
			changeRegion($(this).attr('data-region'));
			return false;
		});

		_this.find('.map-region').click(function() {
			changeRegion($(this).attr('data-region'));
			return false;
		});

		return _this;

	};

})(jQuery);