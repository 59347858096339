(function($) {

	$.fn.initMenuClick = function() {

		this.click(function() {

			$('#nav-icon2').toggleClass('open');


			if($(".menu-mobile").is(".open-menu")){

				$(".menu-mobile").removeClass("open-menu");
				$(".content").removeClass("dark");
				$(".gray-background-middle").removeClass("dark");
				$(".c-inspiration").removeClass("dark");
				$(".c-navigation-footer").removeClass("dark");

			}else {

				$(".menu-mobile").addClass("open-menu");
				$(".content").addClass("dark");
				$(".gray-background-middle").addClass("dark");
				$(".c-inspiration").addClass("dark");
				$(".c-navigation-footer").addClass("dark");

			}


		});

		return this;


	}

})(jQuery);


$(function() {

	$("#menu-button").initMenuClick();

  var lastScrollTop = 0;
  /*$(window).scroll(function(event){
    var st = $(this).scrollTop();
    if (st > lastScrollTop){
      if(st > 300) {

        $(".c-header-new").addClass("hide");

      }
    } else {
      $(".c-header-new").removeClass("hide");
    }
    lastScrollTop = st;
  });*/

});

