$(function() {

	var sirka = $(window).width();

	if (sirka < 480){

	$(".realization").slidehide({
		"x":0, "y":0, "alpha":true, "auto":10000,
		"slides":".item",
		"contHeight":true,
		"grouping": function(){
			var sirka = $(window).width();
			if (sirka < 480) {
				return 1;

			}else {
				return 6;
			}
		},
		"buttons":"#links-button-delivery",
		"touch":true
	});

	}

});
